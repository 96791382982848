<template>
    <div class="alarm-list-view">
        <div class="ctrl-bar">
            <div class="label">名称：</div>
            <n-input
                v-model:value="filterName"
                size="small"
                class="input"
                style="width: 200px"
                clearable
                @keyup="inputKeyup"
            ></n-input>
            <div class="label">创建人：</div>
            <n-input
                v-model:value="filterCreator"
                size="small"
                class="input"
                style="width: 200px"
                clearable
                @keyup="inputKeyup"
            ></n-input>
            <n-button
                size="small"
                type="primary"
                class="input"
                @click="onSearch"
            >
                <template #icon>
                    <n-icon>
                        <search-filled />
                    </n-icon>
                </template>
                查询
            </n-button>
        </div>
        <div class="content">
            <n-data-table
                class="table"
                :columns="tableColumns"
                :data="tableData"
                :loading="tableLoading"
            ></n-data-table>
            <div class="pagination">
                <n-pagination
                    v-model:page="pageIndex"
                    v-model:page-size="pageSize"
                    :page-count="pageCount"
                    show-size-picker
                    :page-sizes="[20, 50, 100]"
                    @update:page="onPageIndexChange"
                    @update:page-size="onPageSizeChange"
                ></n-pagination>
            </div>
        </div>
        <alarm-editor-drawer
            v-model:show="isAlarmEditorDrawerShow"
            :data="selectAlarmInfo"
            @finished="loadData"
        />
        <alarm-view-drawer
            v-model:show="isAlarmInfoDrawerShow"
            :data="selectAlarmInfo"
        />
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';
.alarm-list-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
    }

    .content {
        margin-top: 10px;
        padding-bottom: 100px;

        .table {
            background-color: #fff;
        }

        .pagination {
            display: flex;
            flex-direction: row;
            justify-content: right;
            margin-top: 10px;
        }
    }
}
</style>

<script setup>
import { ref, h, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    NButton,
    NInput,
    NIcon,
    NDataTable,
    NPagination,
    NSwitch,
    useMessage,
} from 'naive-ui';
import { SearchFilled } from '@vicons/material';

import dayjs from 'dayjs';

import CtrlCell from '@/components/CtrlCell';
import AlarmEditorDrawer from './components/AlarmEditorDrawer';
import AlarmViewDrawer from './components/AlarmViewDrawer';

import { AlertAPI } from '@/common/API';
import { useUserStore } from '@/stores/global';

const store = useUserStore();

const route = useRoute();
const router = useRouter();

const message = useMessage();

let tableColumns = ref([
    {
        title: '名称',
        key: 'name',
        ellipsis: true,
        resizable: true,
        minWidth: 200,
        render(row) {
            return (
                <NButton text onClick={() => showAlarmInfo(row)}>
                    {row.name}
                </NButton>
            );
        },
    },
    {
        title: '状态',
        key: 'status',
        width: 100,
        align: 'center',
        render(row) {
            let color =
                {
                    Running: '#3f7ee8',
                    Stoped: '#999',
                    Failed: '#c03f53',
                }[row.status] || null;
            return h('span', { style: { color } }, row.status);
        },
    },
    {
        title: '启停',
        align: 'center',
        width: 60,
        render(row) {
            return (
                <NSwitch
                    size="small"
                    checkedValue={'Running'}
                    uncheckedValue={'Stoped'}
                    disabled={store.currentUser.username !== row.creator}
                    v-model:value={row.status}
                    onUpdate:value={value => setAlertStatus(row.id, value)}
                />
            );
        },
    },
    {
        title: '创建人',
        key: 'creator',
        align: 'center',
        width: 80,
    },
    {
        title: '创建时间',
        key: 'create_time',
        align: 'center',
        width: 120,
        render(row) {
            return dayjs(row.create_time * 1000).format('YYYY-MM-DD');
        },
    },
    {
        title: '',
        align: 'center',
        width: 80,
        render(row) {
            return (
                <CtrlCell
                    row={row}
                    disableItems={
                        store.currentUser.username !== row.creator
                            ? ['edit', 'delete']
                            : []
                    }
                    deleteConfirm="确定删除该监控?"
                    onEdit={() => showAlarmEditor(row)}
                    onDelete={() => deleteAlert(row.id)}
                />
            );
        },
    },
]);

let tableData = ref([]);

let tableLoading = ref(false);

let pageIndex = ref(+route.query.index || 1);

let pageCount = ref(1);

let pageSize = ref(+route.query.size || 20);

let filterName = ref('');

let filterCreator = ref('');

function onSearch() {
    router.replace({
        query: {
            ...(filterName.value !== '' ? { name: filterName.value } : null),
            ...(filterCreator.value !== ''
                ? { creator: filterCreator.value }
                : null),
            index: 1,
            size: route.query.size || 20,
            t: new Date().getTime(),
        },
    });
}

function inputKeyup(e) {
    if (e.key === 'Enter') {
        onSearch();
    }
}

let isAlarmInfoDrawerShow = ref(false);

let isAlarmEditorDrawerShow = ref(false);

let selectAlarmInfo = ref({});

function showAlarmInfo(info) {
    selectAlarmInfo.value = info;
    isAlarmInfoDrawerShow.value = true;
}

function showAlarmEditor(info) {
    selectAlarmInfo.value = info;
    isAlarmEditorDrawerShow.value = true;
}

function onPageIndexChange(index) {
    router.replace({ query: { ...route.query, index } });
}

function onPageSizeChange(size) {
    pageSize.value = size;
    router.replace({
        query: { ...route.query, index: 1, size, t: new Date().getTime() },
    });
}

function loadData() {
    tableLoading.value = true;
    AlertAPI.getList(
        pageIndex.value,
        pageSize.value,
        filterName.value,
        filterCreator.value
    ).then(res => {
        tableLoading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        tableData.value = res.data.list;
        pageCount.value =
            Math.floor(res.data.total / pageSize.value) +
            (res.data.total % pageSize.value !== 0 ? 1 : 0);
    });
}

function deleteAlert(id) {
    AlertAPI.deleteAlert(id).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        loadData();
    });
}

function setAlertStatus(id, status) {
    AlertAPI.updateAlert(id, { status }).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            loadData();
            return;
        }
    });
}

loadData();

watch(route, val => {
    if (val.name !== 'alarm_list') {
        return;
    }
    pageIndex.value = +route.query.index || 1;
    pageSize.value = +route.query.size || 20;
    filterName.value = route.query.name || '';
    filterCreator.value = route.query.creator || '';
    loadData();
});
</script>
