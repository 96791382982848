<template>
    <n-drawer :show="props.show" @mask-click="onMaskClick" :width="800">
        <n-drawer-content title="编辑监控告警">
            <n-form
                ref="formRef"
                :model="formData"
                :rules="formRules"
                label-placement="left"
                :label-width="90"
            >
                <n-form-item label="名称" path="name">
                    <n-input v-model:value="formData.name"></n-input>
                </n-form-item>
                <n-form-item label="数据类型">
                    <n-input v-model:value="formData.sourceType"></n-input>
                </n-form-item>
                <n-form-item label="数据源">
                    <n-input
                        v-model:value="editableSource"
                        type="textarea"
                        :autosize="{ minRows: 8, maxRows: 12 }"
                    ></n-input>
                </n-form-item>
                <n-form-item label="触发条件">
                    <n-input-group>
                        <n-select
                            v-model:value="formData.trigger.type"
                            style="width: 200px"
                            :options="triggerTypeOpts"
                        ></n-select>
                        <n-select
                            v-model:value="formData.trigger.operator"
                            style="width: 200px"
                            :options="triggerOperatorOpts"
                        ></n-select>
                        <n-input-number
                            v-model:value="formData.trigger.value"
                        ></n-input-number>
                    </n-input-group>
                </n-form-item>
                <n-form-item label="Token" path="token">
                    <n-input v-model:value="formData.token"></n-input>
                </n-form-item>
                <n-form-item label="关注人">
                    <n-dynamic-tags
                        v-model:value="formData.follow"
                    ></n-dynamic-tags>
                </n-form-item>
            </n-form>
            <template #footer>
                <div class="footer">
                    <n-button
                        type="info"
                        style="margin-right: 10px"
                        secondary
                        :loading="triggerLoading"
                        @click="triggerNow"
                        >立刻触发</n-button
                    >
                    <n-button type="primary" :loading="loading" @click="submit"
                        >提交</n-button
                    >
                </div>
            </template>
        </n-drawer-content>
    </n-drawer>
</template>

<style lang="less" scoped>
.footer {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
</style>

<script setup>
import { ref, watch } from 'vue';
import {
    NDrawer,
    NDrawerContent,
    NForm,
    NFormItem,
    NInput,
    NInputNumber,
    NSelect,
    NInputGroup,
    NButton,
    NDynamicTags,
    useMessage,
} from 'naive-ui';

import { AlertAPI } from '@/common/API';

const message = useMessage();

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    data: {
        type: Object,
        default() {
            return {};
        },
    },
});

watch(
    () => props.show,
    val => {
        if (!val) {
            return;
        }
        formData.value = { ...props.data };
        editableSource.value = JSON.stringify(
            formData.value.source,
            null,
            '  '
        );
    }
);

const events = defineEmits(['update:show', 'finished']);

function onMaskClick() {
    events('update:show', false);
}

let triggerTypeOpts = ref([
    {
        label: '成本值',
        value: 0,
    },
    {
        label: '同比',
        value: 1,
    },
    {
        label: '环比',
        value: 2,
    },
]);

let triggerOperatorOpts = ref([
    {
        label: '>=',
        value: 0,
    },
    {
        label: '<=',
        value: 1,
    },
]);

let formRef = ref();

let formRules = ref({
    name: {
        required: true,
        message: '名称不能为空',
        trigger: 'blur',
    },
    token: {
        required: true,
        message: 'Token不能为空',
        trigger: 'blur',
    },
});

let editableSource = ref('');

let formData = ref({
    name: '',
    trigger: {
        type: 0,
        operator: 0,
        value: 0,
    },
    token: '',
    follow: [],
});

let loading = ref(false);

function submit() {
    formRef.value.validate(errors => {
        if (errors) {
            return;
        }
        loading.value = true;
        formData.value.source = JSON.parse(editableSource.value);
        AlertAPI.updateAlert(props.data.id, formData.value).then(res => {
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            loading.value = false;
            events('update:show', false);
            events('finished');
        });
    });
}

let triggerLoading = ref(false);

function triggerNow() {
    triggerLoading.value = true;
    AlertAPI.triggerAlert(props.data.id)
        .then(res => {
            triggerLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            message.success('操作完成!');
        })
        .catch(err => {
            triggerLoading.value = false;
            console.log(err);
        });
}
</script>
