<template>
    <n-drawer :show="props.show" @mask-click="onMaskClick" :width="800">
        <n-drawer-content :title="props.data.name || '查看'">
            <div class="info-list">
                <div class="inline-box">
                    <div class="item">
                        <div class="label">数据类型</div>
                        <div class="content">{{ props.data.sourceType }}</div>
                    </div>
                    <div class="item">
                        <div class="label">创建人</div>
                        <div class="content">{{ props.data.creator }}</div>
                    </div>
                    <div class="item">
                        <div class="label">状态</div>
                        <div
                            :style="{
                                color: {
                                    Running: '#3f7ee8',
                                    Stoped: '#999',
                                    Failed: '#c03f53',
                                }[props.data.status],
                            }"
                            class="content"
                        >
                            {{ props.data.status }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">创建时间</div>
                        <div class="content">
                            {{
                                dayjs(props.data.create_time * 1000).format(
                                    'YYYY-MM-DD'
                                )
                            }}
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="label">数据源</div>
                    <div class="content">
                        <n-input
                            :value="
                                JSON.stringify(props.data.source, null, '  ')
                            "
                            type="textarea"
                            :autosize="{ minRows: 10, maxRows: 14 }"
                            readonly
                        />
                    </div>
                </div>
                <div class="item">
                    <div class="label">触发条件</div>
                    <div class="content">
                        {{
                            ['成本值', '同比', '环比'][props.data.trigger.type]
                        }}
                        {{ ['>=', '<='][props.data.trigger.operator] }}
                        {{ props.data.trigger.value }}
                    </div>
                </div>
                <div class="item">
                    <div class="label">Token</div>
                    <div class="content">{{ props.data.token }}</div>
                </div>
                <div class="item">
                    <div class="label">关注人</div>
                    <div class="content">
                        {{ props.data.follow.length < 1 ? '无' : '' }}
                        <n-tag
                            v-for="item in props.data.follow"
                            style="margin-right: 8px"
                            >{{ item }}</n-tag
                        >
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</template>

<style lang="less" scoped>
.info-list {
    .inline-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .item {
        margin-bottom: 10px;

        .label {
            font-weight: bold;
            margin-bottom: 4px;
        }
    }
}
</style>

<script setup>
// import { ref } from 'vue';
import { NDrawer, NDrawerContent, NInput, NTag } from 'naive-ui';
import dayjs from 'dayjs';

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    data: {
        type: Object,
        default() {
            return {};
        },
    },
});

const events = defineEmits(['update:show']);

function onMaskClick() {
    events('update:show', false);
}
</script>
